import React from 'react';
import { Button, Flex, Input, Switch, Typography } from 'antd';
import PaginatedSelect from '../../../UI/Select';
import { translations } from '../../../../utils/translations';

const { Title } = Typography;

const ModifierForm = ({ edit, onFinish, item, setItem, user }) => {
	return (
		<Flex vertical justify='center' align='flex-start'>
			<Title level={3}>{translations.modifier}</Title>
			<br />
			<Flex vertical style={{ width: '40%' }}>
				<span>{translations.name}</span>
				<Input
					value={item?.name || ''}
					onChange={({ target: { value } }) => {
						setItem({
							...item,
							name: value,
						});
					}}
					style={{
						marginRight: 12,
						marginBottom: 12,
						marginTop: 4,
					}}
				/>
			</Flex>
			<br />
			<Flex vertical style={{ width: 100 }}>
				<span>{translations.type}</span>
				<Switch
					style={{ marginTop: 4 }}
					checkedChildren={translations.checkbox}
					unCheckedChildren={translations.radio}
					checked={item?.type}
					onChange={checked => {
						setItem({ ...item, type: checked });
					}}
				/>
			</Flex>
			<br />
			<Flex vertical style={{ width: 100 }}>
				<span>{translations.required}</span>
				<Switch
					checkedChildren={translations.yes}
					unCheckedChildren={translations.no}
					style={{ marginTop: 4 }}
					checked={item?.required}
					onChange={checked => {
						setItem({ ...item, required: checked });
					}}
				/>
			</Flex>
			<br />
			<Flex vertical style={{ marginBottom: 22, width: '40%' }}>
				<span>{translations.options}</span>
				<PaginatedSelect
					multiple={'multiple'}
					basePath={`/modifier-options`}
					user={user}
					parentData={item}
					setParentData={setItem}
					name={'options'}
					params={{ shop: item?.shop, modifier: item?.id }}
					edit={edit}
				/>
			</Flex>
			<br />
			<Button type='primary' onClick={onFinish}>
				{translations.submit}
			</Button>
		</Flex>
	);
};
export default ModifierForm;
