export const RoutesNames = {
	HOME: '/',
	USERS: '/users',
	ADD_USERS: '/users/add',
	EDIT_USER: '/users/edit/:id',
	SHOPS: '/shops',
	SHOP: '/shop/:id',
	ADD_SHOP: '/shop/add',
	CUISINES: '/cuisines',
	ADD_CUISINES: '/cuisines/add',
	EDIT_CUISINE: '/cuisines/edit/:id',
	LANGUAGES: '/languages',
	HISTORY: '/history',
	SETTINGS: '/settings',
	CITIES: '/cities',
	ADD_CITY: '/cities/add',
	EDIT_CITY: '/cities/edit/:id',
	PRINT: '/print',
};
