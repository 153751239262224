import React from 'react';
import { Button, Divider, Flex, Form, Tag } from 'antd';
import Shop from './Shop';
import Address from './Address';
import UploadImage from '../../../UI/UploadImage';
import User from './User';
import PaginatedSelect from '../../../UI/Select';
import { translations } from '../../../../utils/translations';

const layout = {
	labelCol: {
		span: 2,
	},
	wrapperCol: {
		span: 18,
	},
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
	},
	number: {
		range: '${label} must be a valid mobile number! (10 digits)',
	},
};

const Info = ({ shopData, setShopData, onFinish, user }) => {
	const [form] = Form.useForm();
	const isAdmin = user?.role === 0;

	return isAdmin ? (
		<Form
			{...layout}
			form={form}
			name='shop'
			onFinish={onFinish}
			style={{
				width: '100%',
			}}
			validateMessages={validateMessages}
		>
			<Flex justify='center' align='center'>
				<Button
					type='primary'
					style={{
						float: 'right',
						height: 40,
						marginBottom: 16,
						marginRight: 12,
						padding: '0 24px',
					}}
					onClick={onFinish}
				>
					{translations.saveShop}
				</Button>
			</Flex>
			<br />

			<Shop setShopData={setShopData} shopData={shopData} />
			<br />
			<br />
			<Divider
				style={{
					borderColor: '#7cb305',
				}}
			>
				{translations.address}
			</Divider>
			<Address layout={layout} shopData={shopData} setShopData={setShopData} />
			<br />
			<br />
			<Divider
				style={{
					borderColor: '#7cb305',
				}}
			>
				{translations.gallery}
			</Divider>
			<UploadImage
				parentData={shopData}
				setParentData={setShopData}
				array={['Logo', 'Εξώφυλλο']}
			/>

			<br />
			<br />
			<Divider
				style={{
					borderColor: '#7cb305',
				}}
			>
				{translations.cuisines}
			</Divider>
			<PaginatedSelect
				multiple={'multiple'}
				basePath={`/cuisines`}
				user={user}
				parentData={shopData}
				setParentData={setShopData}
				name={'cuisines'}
				edit={true}
			/>
			<br />
			<br />
			<br />
			<Divider
				style={{
					borderColor: '#7cb305',
				}}
			>
				{translations.adminInfo}
			</Divider>
			<User shopData={shopData} setShopData={setShopData} />
		</Form>
	) : (
		<Form
			{...layout}
			form={form}
			name='shop'
			onFinish={onFinish}
			style={{
				width: '100%',
			}}
			validateMessages={validateMessages}
		>
			<Flex justify='center' align='center'>
				<Button
					type='primary'
					style={{
						float: 'right',
						height: 40,
						marginBottom: 16,
						marginRight: 12,
						padding: '0 24px',
					}}
					onClick={onFinish}
				>
					{translations.saveShop}
				</Button>
			</Flex>
			<br />

			<Shop setShopData={setShopData} shopData={shopData} />
			<br />
			<br />
			<Divider
				style={{
					borderColor: '#7cb305',
				}}
			>
				{translations.address}
			</Divider>
			<Address layout={layout} shopData={shopData} setShopData={setShopData} />
		</Form>
	);
};

export default Info;
