import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLiveOrdersRequests } from '../../../redux/Reducer';

export const LiveOrderRequest = ({
	order,
	liveOrdersRequests,
	onOpen,
	onClose,
	onRemoveOrderRequest,
	onAccept,
	onDecline,
}) => {
	const [timer, setTimer] = useState(null);

	const intervalRef = useRef();

	const lorRef = useRef(liveOrdersRequests);

	useEffect(() => {
		lorRef.current = liveOrdersRequests;
	}, [liveOrdersRequests]);

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			const secondsLeft = (new Date(order.orderIgnoredDate) - new Date()) / 1000;
			// console.log(secondsLeft);
			setTimer(secondsLeft > 0 ? Math.floor(secondsLeft) : '');
			if (!(secondsLeft > 0)) {
				onClose(order.id, lorRef.current);
				onRemoveOrderRequest(order.id, lorRef.current);
			}
		}, 1000);
		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);

	return (
		<div style={{ minWidth: 100 }}>
			<div>{order.id}</div>
			<div>{order.price}</div>
			{/* <div onClick={onAccept}>Αποδοχή</div> */}
			{/* <div onClick={onDecline}>Απόρριψη</div> */}
			<div>{timer}</div>
		</div>
	);
};
