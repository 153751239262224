import { useDispatch, useSelector } from 'react-redux';
import { useLiveOrders } from '../../../utils/useLiveOrders';
import { LiveOrderRequest } from './LiveOrderRequest';
import { useEffect, useState } from 'react';
import Order from '../order/Order';
import { setLiveOrdersRequests } from '../../../redux/Reducer';

export const LiveOrdersRequests = () => {
	const { user } = useSelector(state => state);
	const { liveOrdersRequests, onRemoveOrderRequest, onAccept, onDecline } = useLiveOrders();

	const [selectedOrder, setSelectedOrder] = useState(null);

	useEffect(() => {
		if (!selectedOrder) {
			if (liveOrdersRequests?.length) {
				setSelectedOrder(liveOrdersRequests[0]);
				return;
			}
		} else {
		}
	}, [liveOrdersRequests]);

	// const onOpen = order => {
	// 	setSelectedOrder(order);
	// };

	const onClose = (id, refOrders) => {
		const orders = refOrders || liveOrdersRequests;
		console.log('will close', orders?.length);
		if (orders?.length === 1) {
			setSelectedOrder(null);
			return;
		}
		if (orders?.length > 1) {
			const ord = orders.find(lo => lo.id !== id);
			console.log('found ', ord || null);

			setSelectedOrder(ord || null);
		}
	};

	if (!(user?.role === 1)) {
		return null;
	}

	return (
		<div style={{ position: 'relative' }}>
			<div
				style={{
					position: 'fixed',
					bottom: 30,
					width: '80%',
					display: 'flex',
					flexDirection: 'row',
					columnGap: 14,
					marginLeft: '10%',
					alignItems: 'center',
					backgroundColor: 'red',
					overflowX: 'auto',
				}}
			>
				<div> length is {liveOrdersRequests?.length}</div>
				{selectedOrder && (
					<div>
						<Order item={selectedOrder} />
					</div>
				)}
				{liveOrdersRequests.map(lor => (
					<LiveOrderRequest
						key={lor.id}
						order={lor}
						liveOrdersRequests={liveOrdersRequests}
						// onOpen={onOpen}
						onRemoveOrderRequest={onRemoveOrderRequest}
						onClose={onClose}
						onAccept={() => {
							onAccept(lor);
							if (selectedOrder.id === lor.id) {
								setSelectedOrder(
									liveOrdersRequests?.length ? liveOrdersRequests[0] : null,
								);
							}
						}}
						onDecline={() => {
							onDecline(lor);
							if (selectedOrder.id === lor.id) {
								setSelectedOrder(
									liveOrdersRequests?.length ? liveOrdersRequests[0] : null,
								);
							}
						}}
					/>
				))}
			</div>
		</div>
	);
};
