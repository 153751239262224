import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RoutesNames } from '../../router/RouteNames';
import api from '../../utils/http';
import { CuisinesColumns } from '../../columns/CuisinesColumns';
import Table from '../UI/Table';
import { setCuisine } from '../../redux/Reducer';
import { translations } from '../../utils/translations';

const { Title } = Typography;

const CuisineTable = ({ data, setPageNumber, pageSize, setPageSize, totalPages }) => {
	const navigate = useNavigate();

	return (
		<Flex vertical>
			<Flex justify='space-between' align='center'>
				<Title>{translations.cuisines}</Title>

				<Button
					type='primary'
					onClick={() => {
						navigate(RoutesNames.ADD_CUISINES);
					}}
				>
					{translations.addCuisine}
				</Button>
			</Flex>

			<Table
				data={data}
				rowKey='id'
				setPageNumber={setPageNumber}
				pageSize={pageSize}
				setPageSize={setPageSize}
				editPath='/cuisines/edit/'
				basePath='/cuisines/'
				queryParams='id'
				columns={CuisinesColumns}
				setReduxAction={setCuisine}
				totalPages={totalPages}
			/>
		</Flex>
	);
};

export default CuisineTable;
