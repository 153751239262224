// import React from 'react';
// import { BlobProvider,Document,
// 	Page,
// 	Text,
// 	View,pdf,
// 	StyleSheet, } from '@react-pdf/renderer';
// import { Doc } from './TestPdf'; // Your PDF document component

// // export const PrintButton = () => {
// //   // const printPDF = (blobUrl) => {
// // 	// 	try {

// //   //   const iframe = document.createElement('iframe');
// //   //   iframe.style.display = 'none';
// //   //   iframe.src = blobUrl;
// //   //   document.body.appendChild(iframe);
// //   //   iframe.onload = function () {
// //   //     iframe.contentWindow.print(); // Automatically trigger the print dialog
// //   //     document.body.removeChild(iframe); // Clean up after printing
// //   //   };
// // 	// }

// // 	// catch (err) {
// // 	// 	console.log('aaaa', err)
// // 	// }
// //   // };

// // 	// const printPDF = (blob) => {
// //   //   const blobUrl = window.URL.createObjectURL(blob); // Create object URL from blob
// //   //   const iframe = document.createElement('iframe');
// //   //   iframe.style.display = 'none';
// //   //   iframe.src = blobUrl; // Assign the blob URL to iframe's src
// //   //   document.body.appendChild(iframe);
// //   //   iframe.onload = function () {
// //   //     iframe.contentWindow.print(); // Trigger print dialog
// //   //     document.body.removeChild(iframe); // Clean up after printing
// //   //     window.URL.revokeObjectURL(blobUrl); // Revoke the object URL
// //   //   };
// //   // };

// // 	const printPDF = (blob) => {
// //     const blobUrl = URL.createObjectURL(blob); // Create object URL from blob
// //     const iframe = document.createElement('iframe');
// //     iframe.style.display = 'none';
// //     iframe.src = blobUrl;
// //     document.body.appendChild(iframe);
// //     iframe.onload = function () {
// //       iframe.contentWindow.print(); // Automatically trigger the print dialog
// //       document.body.removeChild(iframe); // Clean up after printing
// //       URL.revokeObjectURL(blobUrl); // Revoke the object URL
// //     };
// //   };

// //   return (
// //     <BlobProvider document={<Document>
// // 			<Page size={140} style={{
// // 		flexDirection: 'column',
// // 		// paddingHorizontal: 24,
// // 		backgroundColor: 'white',
// // 	}}>
// // 				<Text style={[ { fontSize: 2}]}>FONT 2</Text>
// // 				<Text style={[ { fontSize: 4}]}>FONT 4</Text>
// // 				<Text style={[ { fontSize: 6}]}>FONT 6</Text>
// // 				<Text style={[ { fontSize: 8}]}>FONT 8</Text>
// // 				<Text style={[ { fontSize: 10}]}>FONT 10</Text>
// // 				<Text style={[ { fontSize: 12}]}>FONT 12</Text>
// // 				<Text style={[ { fontSize: 14}]}>FONT 14</Text>

// // 			</Page>
// // 		</Document>}>
// //       {({ blob, loading }) => (
// //         <button onClick={() => !loading && blob && printPDF(blob)}>
// //           {loading ? 'Loading...' : 'Print PDF'}
// //         </button>
// //       )}
// //     </BlobProvider>
// //   );
// // };

// export const PrintButton = () => {
// 	const downloadPDF = async (document, filename = 'document.pdf') => {
// 		// Generate the PDF blob
// 		const blob = await pdf(document).toBlob();

// 		if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
// 			// For IE and Edge browsers
// 			window.navigator.msSaveOrOpenBlob(blob, filename);
// 		} else if (typeof window !== 'undefined' && window.document) {
// 			// For other browsers
// 			const url = window.URL.createObjectURL(blob);
// 			const link = window.document.createElement('a');
// 			link.href = url;
// 			link.rel="noopener noreferrer"
// 			link.target = '_blank'
// 			link.download = filename;

// 			// Append to the document, click it, and remove it
// 			window.document.body.appendChild(link);
// 			link.click();
// 			window.document.body.removeChild(link);

// 			// Clean up by revoking the blob URL
// 			setTimeout(() => window.URL.revokeObjectURL(url), 100);
// 		} else {
// 			// Fallback for non-browser environments
// 			console.error('Downloading is only supported in browser environments.');
// 		}
// 	};

// // Usage example
// const handleDownload = () => {
//   downloadPDF(<Document>
// 		<Page size={140} style={{
//  flexDirection: 'column',
//  // paddingHorizontal: 24,
//  backgroundColor: 'white',
// }}>
// 		 <Text style={[ { fontSize: 2}]}>FONT 2</Text>
// 		 <Text style={[ { fontSize: 4}]}>FONT 4</Text>
// 		 <Text style={[ { fontSize: 6}]}>FONT 6</Text>
// 		 <Text style={[ { fontSize: 8}]}>FONT 8</Text>
// 		 <Text style={[ { fontSize: 10}]}>FONT 10</Text>
// 		 <Text style={[ { fontSize: 12}]}>FONT 12</Text>
// 		 <Text style={[ { fontSize: 14}]}>FONT 14</Text>

// 	 </Page>
//  </Document>, 'my-document.pdf');
// };

// // In your component
// return (
//   <button onClick={handleDownload}>Download PDF</button>
// );
// }

import React, { useEffect, useState, useRef } from 'react';
// import React from 'react';
import {
	BlobProvider,
	Document,
	Page,
	Text,
	View,
	pdf,
	PDFDownloadLink,
	StyleSheet,
} from '@react-pdf/renderer';
// import MyDocument from "./MyDocument"; // Your PDF document component

export const PrintButton = () => {
	const iframeRef = useRef(null);
	const [pdfBlobUrl, setPdfBlobUrl] = useState(null);

	useEffect(() => {
		const generatePdf = async () => {
			const blob = await pdf(
				<Document>
					<Page
						size={140}
						style={{
							flexDirection: 'column',
							// paddingHorizontal: 24,
							backgroundColor: 'white',
						}}
					>
						<Text style={[{ fontSize: 2 }]}>FONT 2</Text>
						<Text style={[{ fontSize: 4 }]}>FONT 4</Text>
						<Text style={[{ fontSize: 6 }]}>FONT 6</Text>
						<Text style={[{ fontSize: 8 }]}>FONT 8</Text>
						<Text style={[{ fontSize: 10 }]}>FONT 10</Text>
						<Text style={[{ fontSize: 12 }]}>FONT 12</Text>
						<Text style={[{ fontSize: 14 }]}>FONT 14</Text>
					</Page>
				</Document>,
			).toBlob();
			const blobUrl = URL.createObjectURL(blob);
			setPdfBlobUrl(blobUrl);
		};
		generatePdf();
	}, []);

	// Automatically print when iframe loads
	useEffect(() => {
		if (iframeRef.current && pdfBlobUrl) {
			iframeRef.current.src = pdfBlobUrl;

			iframeRef.current.onload = () => {
				if (!iframeRef.current?.contentWindow) {
					return;
				}
				setTimeout(() => {
					iframeRef.current.contentWindow.focus();
					iframeRef.current.contentWindow.print();
					setTimeout(() => {
						iframeRef.current.contentWindow.focus();
						iframeRef.current.contentWindow.close();
					}, 2000);
					console.log('aaaaaa', iframeRef.current.contentWindow);
					// iframeRef.current.contentWindow.afterPrint();
				}, 150);
			};
			// console.log('ddd', iframeRef.current.contentWindow)
			// iframeRef.current.afterprint = () => {
			// 	console.log('aafter')
			// }
		}
	}, [pdfBlobUrl]);

	return <>{pdfBlobUrl && <iframe ref={iframeRef} style={{ display: 'none' }} title='pdf' />}</>;
	//   return (
	//     <>
	//       <PDFDownloadLink document={<Document>
	//  		<Page size={140} style={{
	//  flexDirection: 'column',
	//  // paddingHorizontal: 24,
	//  backgroundColor: 'white',
	// }}>
	// 		 <Text style={[ { fontSize: 2}]}>FONT 2</Text>
	// 		 <Text style={[ { fontSize: 4}]}>FONT 4</Text>
	// 		 <Text style={[ { fontSize: 6}]}>FONT 6</Text>
	// 		 <Text style={[ { fontSize: 8}]}>FONT 8</Text>
	// 		 <Text style={[ { fontSize: 10}]}>FONT 10</Text>
	// 		 <Text style={[ { fontSize: 12}]}>FONT 12</Text>
	// 		 <Text style={[ { fontSize: 14}]}>FONT 14</Text>

	// 	 </Page>
	//  </Document>}
	//  fileName="my-fileeqwe.pdf">
	//         {({ blob, url, loading, error }) =>
	//           !loading && pdfBlobUrl && (
	//             <iframe
	//               ref={iframeRef}
	//               src={url}
	//               style={{ display: "none" }}
	//               title="pdf"
	//             />
	//           )
	//         }
	//       </PDFDownloadLink>
	//     </>
	//   );
};
