import React, { useState } from 'react';
import CuisineForm from '../../components/cuisines/CuisineForm';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RoutesNames } from '../../router/RouteNames';
import api from '../../utils/http';
import { useSelector } from 'react-redux';

const AddCuisineHOC = () => {
	const { user } = useSelector(state => state);
	const navigate = useNavigate();
	const { postGlobal } = api();

	const [item, setItem] = useState({ name: '', files: [] });

	const onFinish = () => {
		postGlobal('/cuisines', {
			...item,
			language: user.language,
			files: item.files.map(el => el.id),
		})
			.then(() => {
				message.success('Cuisine created successfully');
				navigate(RoutesNames.CUISINES);
			})
			.catch(error => message.error(error.response.data.message));
	};
	return (
		<CuisineForm edit={false} onFinish={onFinish} cuisineData={item} setCuisineData={setItem} />
	);
};

export default AddCuisineHOC;
