import React, { useEffect } from 'react';
import { Button, Col, Flex, message, Row, Typography } from 'antd';
import Order from '../order/Order';
import { useFocusPage } from '../../../utils/useFocusPage';
import { useSockets } from '../../../utils/useSockets';
import api from '../../../utils/http';
import { useDispatch, useSelector } from 'react-redux';
import { setLiveOrders, setLiveOrdersRequests } from '../../../redux/Reducer';

const LiveOrders = () => {
	const styles = {
		padding: 8,
		border: '1px solid #e8e8e8',
		backgroundColor: '#f7f7f7',
	};
	const dispatch = useDispatch();

	const { liveOrders } = useSelector(s => s);

	const { get, patch } = api();

	useEffect(() => {
		get(`/orders?status=0`)
			.then(res => {
				console.log(res);
				if (res?.data?.data) {
					dispatch(setLiveOrdersRequests(res.data.data));
				}
			})
			.catch(err => {
				console.log(err);
			});
		get(`/orders?status=1`)
			.then(res => {
				console.log(res);
				if (res?.data?.data) {
					dispatch(setLiveOrders(res.data.data));
				}
			})
			.catch(err => {
				console.log(err);
			});
	}, []);

	const onRemoveOrderRequest = id => {
		const idx = liveOrders.findIndex(lor => lor.id === id);
		if (idx === -1) {
			return;
		}
		const newLiveOrders = [...liveOrders];
		newLiveOrders.splice(idx, 1);
		dispatch(setLiveOrders(newLiveOrders));
	};

	const onComplete = async id => {
		patch(`/orders/${id}/complete`, {
			// name: item.name,
			// language: user.language,
		})
			.then(response => {
				console.log(response);
				onRemoveOrderRequest(id);

				// setOpen(!open);
				// setItem({});
				message.success('Success!');
			})
			.catch(err => {
				console.log('ERRORRRR', err);

				if (err.response?.data?.code === 404) {
					message.error('Η παραγγελία δεν υπάρχει ή έχει απαντηθεί');
					onRemoveOrderRequest(id);
					return;
				}
				// console.log('ERRORRRR', err.response?.data?.code);
				message.error(err.response?.data?.message);
			});
	};
	// useSockets({ onAccept, onDecline });

	// useFocusPage(() => message.info("Live Orders Page is focused"));

	return (
		<Flex vertical style={styles}>
			{liveOrders.map(lo => (
				<Order key={lo.id} item={lo} onComplete={onComplete} />
			))}
		</Flex>
	);
};

export default LiveOrders;
