import { Switch, Tag } from 'antd';
import { translations } from '../utils/translations';

export const CouponsColumns = onActiveChange => [
	{
		title: <span style={{ color: '#6B7280' }}>ID</span>,
		dataIndex: 'id',
		key: 'id',
		width: 50,
	},
	{
		// title: <span style={{ color: "#6B7280" }}>{translations.code}</span>,
		title: <span style={{ color: '#6B7280' }}>code</span>,
		dataIndex: 'code',
		key: 'code',
		width: 50,
		render: (text, record) => <Tag color={record.active ? 'green' : 'red'}>{record.code}</Tag>,
	},
	{
		// title: <span style={{ color: "#6B7280" }}>{translations.discount}</span>,
		title: <span style={{ color: '#6B7280' }}>discount</span>,
		dataIndex: 'discount',
		key: 'discount',
		width: 50,
		render: (text, record) => (
			<span>{`${record.discount} ${record.type === 0 ? '€' : '%'}`}</span>
		),
	},
	{
		title: (
			//   <span style={{ color: "#6B7280" }}>{translations.minimumOrder}</span>
			<span style={{ color: '#6B7280' }}>minimumOrder</span>
		),
		dataIndex: 'minimumOrder',
		key: 'minimumOrder',
		width: 50,
		render: (text, record) => <span>{record.minimumOrder}</span>,
	},
	{
		title: (
			//   <span style={{ color: "#6B7280" }}>{translations.active}</span>
			<span style={{ color: '#6B7280' }}>active</span>
		),
		dataIndex: 'minimumOrder',
		key: 'minimumOrder',
		width: 50,
		render: (text, record) => (
			<Switch checked={record.active} onChange={() => onActiveChange(record.id)} />
		),
	},
];
