import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCuisine } from '../../redux/Reducer';
import api from '../../utils/http';
import CuisineTable from '../../components/cuisines/CuisineView';

const CuisinesHOC = () => {
	const { user, cuisines } = useSelector(state => state);
	const { getGlobal } = api();
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(0);

	useEffect(() => {
		getGlobal('/cuisines', {
			pageNumber,
			pageSize,
			language: user.language,
		})
			.then(response => {
				response && dispatch(setCuisine(response.data.data));
				setTotalPages(response.data.totalPages);
			})
			.catch(err => {});
	}, [pageNumber, pageSize]);

	return (
		<CuisineTable
			data={cuisines}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
		/>
	);
};

export default CuisinesHOC;
