import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCoupons } from '../../../../redux/Reducer';
// import ItemCategoryTable from "../../../../components/store/shop/itemCategories/ItemCategoryView";
import api from '../../../../utils/http';
import { message } from 'antd';
import CouponsTable from '../../../../components/store/shop/Coupons/CouponView';
// import { AimOutlined } from "@ant-design/icons";

const CouponsHOC = () => {
	const { coupons, user } = useSelector(state => state);
	const { get, patch, post } = api();
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		get(`/coupons`, {
			pageNumber,
			pageSize,
			language: user.language,
		})
			.then(response => {
				response && dispatch(setCoupons(response.data.data));
				setTotalPages(response.data.totalPages);
			})
			.catch(err => {});
	}, [pageNumber, pageSize, open, user]);

	const onFinish = () => {
		patch(`/coupons`, {
			minimumOrder: Number(item.minimumOrder) * 100,
			discount: item.discount * 100,
			type: item.type ? 1 : 0,
		})
			.then(response => {
				setOpen(!open);
				setItem({});
				message.success('Success!');
			})
			.catch(err => {
				message.error(err.response.data.message);
			});
	};

	const onActiveChange = id => {
		patch(`/coupons/status?id=${id}`)
			.then(response => {
				message.success('Success!');
				dispatch(
					setCoupons(
						coupons.map(coupon =>
							coupon.id === id ? { ...coupon, active: !coupon.active } : coupon,
						),
					),
				);
			})
			.catch(err => {
				message.error(err.response.data.message);
			});
	};

	return (
		<CouponsTable
			data={coupons.map(it => ({
				...it,
				minimumOrder: it.minimumOrder / 100,
				discount: it.discount / 100,
			}))}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			item={item}
			setItem={setItem}
			onFinish={onFinish}
			open={open}
			setOpen={setOpen}
			user={user}
			onActiveChange={onActiveChange}
		/>
	);
};

export default CouponsHOC;
